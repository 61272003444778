$color-yellow: #F4DB49;
$color-yellow-dark: #EDD445;
$color-green: #69B554;
$color-duck-green: #224B50;
$color-white: #FFFFFF;
$color-black: #313131;
$color-black-other: #464646;
$color-grey: #FBF8F6;
$color-grey-2: #E7E7E7;
$color-grey-bar: #E6E6E6;
$color-back-2: #F8F8F8;
$color-red: #E14300;