#pagenotfound,
#category{
    .page-wrapper {
        border: none;
        background: transparent;
    }
    .search-widget {
        margin-top: 49px;
    }
    #content {
        .img-error {
            width: 40%;
            margin: 43px auto 30px auto;
            display: block;
        }
        .content-error {
            font-family: $font-title;
            font-weight: 800;
            .h1 {
                color: $color-green;
                text-transform: capitalize;
            }
            p {
                text-align: center;

                a {
                    color: $color-green;
                    text-decoration: underline;
                }
            }
        }
        .block-department-error {
            margin-top: 26px;
            .title-error {
                font-family: $font-title;
                font-size: 20px;
                line-height: 31px;
                color: #2C302E;
                font-weight: 800;
                text-align: center;
            }
            .department-error {
                display: flex;
                justify-content: space-between;
                gap: 31px;
                margin-top: 36px;
                @media (max-width: 992px){
                    gap: 1em;
                    flex-direction: column;
                }
                a {
                    background: $color-grey;
                    text-align: center;
                    padding-top: 23px;
                    padding-bottom: 12px;
                    width: calc(100%/4);
                    @media (max-width: 992px){
                        width: 100%;
                    }
                    span{
                        display: block;
                        font-weight: 700;
                        font-family: $font-title;
                        font-size: 17px;
                        line-height: 31px;
                        color: $color-black;
                        margin-top: 20px;
                    }
                }
                .break {
                    flex-basis: 100%;
                    height: 0;
                }
            }
        }
    }
}