.l-footer {
  color: $text-muted;


  .cms-page-link,
  .account-list a{
    color: $text-muted;
    &:hover{
      color: $color-yellow-dark;

    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
  margin: 0 21px 23px 21px;
  background: $color-grey;
  ul {
    li {
      font-family: $font-title;
      font-weight: 700;
      font-size: 15px;
      line-height: 25px;
      text-transform: uppercase;
      color: $color-duck-green;
    }
  }
}

.footer__title{
  color: $color-green;
  font-family: $font-title;
  font-size: 17px;
  line-height: 25px;
  &:visited,&:hover{
    color: $color-green;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{
      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}

.footer-copyright {
  background: $color-duck-green;
  padding: 11px 124px;
  font-family: $font-title;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
  font-size: 10px;
  line-height: 30px;
  font-weight: 400;
  p {
    margin-bottom: 0;
  }
  svg {
    height: 21px;
  }
  @media (max-width: 992px){
    padding: 10px 27px;
    text-align: center;
  }
}

.separator {
  border: 1.5px solid $color-duck-green;
  width: 100%;
  background: $color-duck-green;
  margin: 38px 0px;
}

.logo-footer {
  margin: 0 auto;
  margin-top: 36px;
}

.footer-text {
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  color: $color-black;
  @media (max-width: 992px){
    margin-top: 30px;
  }
}

.social-btn {
  display: flex;
  justify-content: center;
  a {
    margin-right: 12px;
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  }
}

#decovalblock {
  li {
    color: #6c757d;
  }
}