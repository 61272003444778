.l-header{
  background-color: $header-bg;
  // margin-bottom: $spacer;
  box-shadow: $header-box-shadow;
}

.top-banner {
  position: relative;
  line-height: 2;
  display: none;
  p {
    margin-bottom: 0;
  }
  .close-banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    font-style: normal;
    cursor: pointer;
    &::after {
      content: "x";
      color: $color-duck-green;
    }
  }
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}

.banner-promo {
  background: $color-yellow-dark;
  color: $color-duck-green;
  font-family: $font-title;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 34px;
  padding-top: 8px;
  padding-bottom: 8px;
  &-content {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }
  .btn-close {
    margin-right: 11px;
    cursor: pointer;
  }
}

.icon-svg {
  width: 30px;
  height: 30px;
}

.hidden {
  display: none;
}