.button {
    font-family: $font-title;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-duck-green;
    padding: 14px 0;
    background: $color-yellow;
    display: block;
    cursor: pointer;
    &.disabled {
        background: #FBF8F6;
        border: 1px solid $color-duck-green !important;
    }
    svg {
        fill: $color-duck-green;
        width: 9px;
        height: 15px;
        margin-left: 15px;
    }
    .desktop, &.desktop {
        @media (max-width: 992px){
            display: none;
        }
    }
    .mobile, &.mobile {
        display: none;
        @media (max-width: 992px){
            display: block;
        }
    }
    &:hover {
        background: $color-duck-green;
        color: $color-yellow;
        svg {
            fill: $color-yellow;
        }
    }
    &__lg {
        @extend .button;
        text-transform: uppercase;
    }
    &__md {
        @extend .button;
        text-transform: uppercase;
        width: 182px;
    }
    &__sm {
        @extend .button;
        width: 142px;
    }
    &__maintenance {
        @extend .button;
        width: 40%;
        margin: 0 auto;
        text-transform: uppercase;
    }
    &__technique {
        @extend .button;
        width: 35%;
        text-transform: uppercase;
    }
    @include media-breakpoint-mobile {
        &__maintenance,
        &__technique {
            width: 100%;
        } 
    }
}