#my-account {
    h1 {
        text-transform: capitalize;
    }
    #account-title {
        p {
            margin: 40px 0;
        }
    }
    .account-links{
        div {
            width: calc(100%/7);
            @media (max-width: 992px){
                width: inherit;
                @include make-col(6);
            }
            .link-item {
                border: none;
                color: $color-black;
                line-height: 20px;
                font-weight: 400;
                svg {
                    height: 66px;
                    width: 66px;
                    margin: 0 auto;
                    margin-bottom: 18px;
                }
            }
        }
    }  
}

#identity,
#address,
#addresses,
#order-slip,
#history,
#discount,
#module-ps_emailalerts-account,
#authentication,
#password,
#module-psgdpr-gdpr,
#order-detail{
    h1 {
        text-transform: none;
        text-align: center;
        margin-bottom: 40px;
    }
    h6 {
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 30px;
        font-family: $font-text;
        font-weight: 400;
    }
    label {
        font-family: $font-title;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
    }
    button {
        border: none;
    }
    .address {
        border: none;
        background: $color-grey;
        padding: 26px 28px;
    }
    .address-header {
        border-bottom: 1.5px solid $color-duck-green;
        padding: 0 0 12px 0;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            margin-bottom: 0;
        }
        svg {
            height: 18px;
            width: 18px;
            margin-left: 10px;
        }
        a {
            color: $color-duck-green;
            display: flex;
            align-items: center;
            span {
                @media (max-width: 992px){
                    display: none;
                }
            }
        }
        .h4 {
            font-family: $font-title;
            font-size: 20px;
            line-height: 25px;
            color: $color-green;
            font-weight: 700;
        }
    }
    .list-address {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
    }
    .address-body {
        padding: 0;
        display: flex;
        justify-content: space-between;
        p {
            margin-bottom: 0.2em;
        }
        .btn-delete-address {
            display: flex;
            align-items: flex-end;
            a {
                color: $color-duck-green;
                text-decoration: underline;
            }
        }
    }
    .addresses-footer {
        display: flex;
        justify-content: center;
        a {
            padding: 15px 100px;
        }
    }
    .page-header {
        background-color: transparent;
        border: none;
    }
    .form-footer{
        button {
            margin: 0 auto;
            padding: 15px 20px;
        }
    }
    .form-control {
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        background: none;
        border: 1px solid $color-black;
    }
    .form-group {
        label {
            width: 40%;
        }
        .custom-control-label{
            width: 100%;
        }
        &.radio-buttons-id_gender {
            display: flex;
            justify-content: space-between;
            .radio-group {
                width: 295px;
            }
        }
        &.text-firstname,
        &.text-lastname,
        &.email-email,
        &.text-company,
        &.text-vat_number,
        &.text-alias,
        &.text-address1,
        &.text-address2,
        &.text-postcode,
        &.text-city,
        &.countrySelect-id_country,
        &.tel-phone,
        &.text-siret{
            display: flex;
            justify-content: space-between;
            @media (max-width: 992px){
                flex-direction: column;
            }
            input,select {
                width: 60%;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
        &.password-password,&.password-new_password{
            display: flex;
            justify-content: space-between;
            align-items: center;
            label {
                width: 40%;
            }
            @media (max-width: 992px){
                flex-direction: column;
                align-items: flex-start;
            }
            .form-control {
                border: none;
            }
            .password-block {
                width: 60%;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
            .input-group {
                justify-content: flex-end;
                border: 1px solid $color-black;
                input {
                    width: 60%;
                }
            }
            .form-text {
                text-align: end;
            }
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $color-green;
        border-color: $color-black;
    }
    .separator {
        margin-right: 0;
        margin-left: 0;
    }
    .page-wrapper.page-wrapper--identity,
    .page-wrapper.page-wrapper--address,
    .page-wrapper.page-wrapper--addresses,
    .page-wrapper.page-wrapper--order-slip,
    .page-wrapper.page-wrapper--history,
    .page-wrapper.page-wrapper--discount,
    .page-wrapper.page-wrapper--module-ps_emailalerts-account,
    .page-wrapper.page-wrapper--authentication,
    .page-wrapper.page-wrapper--password,
    .page-wrapper.page-wrapper--module-psgdpr-gdpr,
    .page-wrapper.page-wrapper--order-detail{
        background: none;
        border: none;
    }
    .wrapper-form {
        background: $color-grey;
        padding: 30px 120px;
        width: 80%;
        margin: 0 auto;
        @media (max-width: 992px){
            width: 100%;
            padding: 15px;
        }
    }
    .page-content form, .checkout-form form, .page-wrapper--order-confirmation form {
        width: 100%;
        max-width: 100%;
    }
    thead {
        background: $color-grey;
        font-family: $font-title;
        line-height: 20px;
        font-weight: 600;
        border: none;
        padding: 10px 29px 37px;
        th {
            border: none;
            vertical-align: middle;
            padding: 24px 15px 26px 15px;
            &:first-child {
                padding-left: 30px;
            }
            &:last-child {
                padding-right: 30px;
            }
        }
        &::after {
            line-height: 1em;
            content:'.';
            color: white;
            display: block;
            background: $color-white;
        }
    }
    tbody{
        background: $color-grey;
        tr {
            position: relative;
            padding: 29px 37px;
            &::after {
                content: '';
                position: absolute;
                border-bottom: 1px solid $color-duck-green;
                width: 95%;
                bottom: 0;
                left: 2.5%;
                margin: 0 auto;
            }
            &:last-child::after {
                border-bottom: 0px;
            }
        }
        tr:nth-of-type(odd) {
            background: none;
        }
        td {
            vertical-align: middle;
            padding: 24px 15px 26px 15px;
            a {
                color: $color-green;
                font-weight: 700;
            }
            &:first-child {
                padding-left: 30px;
            }
            &:last-child {
                padding-right: 30px;
            }
        }
        .btn-download {
            background: $color-duck-green;
            color: white;
            font-weight: 400;
            padding: 4px 35px;
        }
    }
    .table-bordered th, .table-bordered td {
        border: none;
    }
    .details-ref{
        font-weight: 700;
        line-height: 23px;
        color: $color-green;
        vertical-align: middle;
    }
    .link-actions{
        line-height: 23px;
        text-decoration: underline;
        color: $color-duck-green;
    }
    .block-forgotten-password{
        .form {
            background: $color-grey;
            padding: 28px 33px;
        }
        button {
            padding-left: 1em;
            padding-right: 1em;
            margin: 0 auto;
        }
    }
    .psgdprgetdatabtn17 {
        float: none;
    }
    .psgdprinfo17 {
        background: $color-grey;
        a {
            width: max-content;
            padding-left: 1em;
            padding-right: 1em;
        }
        div {
            display: flex;
            gap: 1em;
        }
    }
    #exportDataToCsv,
    #exportDataToPdf{
        margin-left: 0;
        margin-right: 0;
    }
    .box {
        .button {
            width: max-content;
            padding-right: 1em;
            padding-left: 1em;
            margin-top: 1em;
        }
    }
}
