#authentication {
    .login-form, 
    .register-form {
        .title-form {
            margin-bottom: 30px;
            p {
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                font-family: $font-title;
                color: $color-green;
                margin-bottom: 0;
            }
            span {
                font-size: 17px;
                line-height: 25px;
            }
        }
        background: $color-grey;
        padding: 28px 33px;
        label, .label {
            margin-bottom: 0;
        }
        .form-group {
            &.email-email,
            &.password-password {
                flex-direction: column;
                align-items: inherit;
                .input-group {
                    border: none;
                    justify-content: flex-start;
                    input {
                        width: 100%;
                    }
                }
                .password-block{
                    width: 100%;
                }
            }
            .form-control {
                width: 100%;
                border: none;
                box-shadow: none;
                border-bottom: 1px solid $color-black;
                padding: 0;
            }
        }
        .forgot-password{
            a {
                color: $color-black;
                text-decoration: underline;
                font-size: 14px;
                line-height: 15px;
            }
        }
        .form-footer {
            button {
                margin: initial;
                width: 100%;
            }
        }
        .block-options {
            display: flex;
            justify-content: space-between;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                svg {
                    height: 66px;
                    width: 66px;
                }
                span {
                    text-align: center;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 10px;
                }
            }
        }
    }
    .register-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}