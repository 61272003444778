#module-opartdevis-simplequotation {
    .page-wrapper {
        border: none;
        background-color: transparent;
    }
    // .block-title {
    //     font-family: $font-title;
    //     font-weight: 800;
    //     font-size: 45px;
    //     line-height: 66px;
    //     color: $color-duck-green;
    // }
}