#checkout {

    #cta-terms-and-conditions-0 {
        color: #212529;
        font-weight: bold;
    }
    .js-alert-payment-conditions {
        margin: 2em 0 0 0;
    }

    p {margin-bottom: 0;}
    .l-header {
        background: $color-grey;
    }
    
    .header-block{
        display: flex;
        justify-content: space-between;
        width: 30%;
        @media(max-width: 992px){
            display: none;
        }
        img {
            margin-right: 10px;
        }
        &-info {
            display: flex;
            font-family: $font-title;
            font-weight: 500;
            color: $color-duck-green;
            a {
                color: $color-duck-green;
            }
            &-bold {
                font-weight: 700;
            }
        }
    }

    #checkout-personal-information-step {
        #content-checkout-personal-information-step {
            .content {
                @include media-breakpoint-mobile {
                    padding: 0;
                }
                .nav-tabs {
                    border: none;
                    .nav-link {
                        @include media-breakpoint-mobile {
                            font-size: 12px;
                        }
                        &:hover {
                            border-color: transparent;
                        }                    
                        &.active {
                            color: $color-duck-green;
                            border-bottom: 5px solid $color-yellow;
                            font-family: $font-title;
                        }
                    }
                }
                .tab-content {
                    background: $color-grey;
                    padding: 1em;
                    #customer-form {
                        max-width: 600px;
                    }
                    #customer-form,#checkout-login-form {
                        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
                            background-image: url(/themes/classic-rocket/assets/img/checked-green.svg);
                            background-size: contain;
                        }
                        .form-fields {
                            .form-group {
                                .label,label {
                                    font-family: $font-title;
                                    font-size: 17px;
                                    color: $color-duck-green;
                                    font-weight: 600;
                                    line-height: 25px;
                                    @include media-breakpoint-mobile {
                                        font-size: 14px;
                                    }
                                }
                                .form-control {
                                    background: transparent;
                                    border: 1px solid $color-duck-green;
                                    font-family: $font-text;
                                    &:focus {
                                        box-shadow: none;
                                    }
                                }
                                &.radio-buttons-id_gender,
                                &.text-company,
                                &.text-siret,
                                &.email-email,
                                &.password-password{
                                    @extend .col-12;
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                                &.text-firstname {
                                    padding-left: 0;
                                }
                                &.text-lastname {
                                    padding-right: 0;
                                }
                                &.text-firstname,&.text-lastname{
                                    @extend .col-6;
                                }
                                &.password-password {
                                    margin-top: 1em;
                                }
                                &.checkbox-customer_privacy,
                                &.checkbox-psgdpr{
                                    .custom-control-label{
                                        font-family: $font-text;
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-weight: 300;
                                        color: $color-black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #checkout-addresses-step {
        .js-address-form {
            background: $color-grey;
            padding: 1em;
            .h4 {
                padding: 20px 98px 0 98px;
            } 
            .different-invoice-address-link {
                padding-left: 1em;
                a {
                    color: $color-duck-green;
                }
            }
            @media (max-width: 992px){
                padding: 21px;
            }
            .description-step{
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 20px;
                padding-left: 1em;
            }
            .button__lg {
                width: 461px;
                border: none;
                margin: 0 auto;
                margin-top: 40px;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
            h2.h4 {
                color: $color-green;
                font-size: 20px;
                line-height: 25px;
                font-weight: 600;
                font-family: $font-title;
            }
            .form-fields {
                padding: 20px 98px;
                .form-group {
                    .label,label{
                        font-family: $font-title;
                        font-size: 17px;
                        color: $color-duck-green;
                        font-weight: 600;
                        line-height: 25px;
                        @include media-breakpoint-mobile {
                            font-size: 14px;
                        }
                    }
                    .form-control,.custom-select {
                        background: transparent;
                        border: 1px solid $color-duck-green;
                        font-family: $font-text;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    &.text-alias,
                    &.text-company,
                    &.text-address1,
                    &.text-address2,
                    &.countrySelect-id_country,
                    &.tel-phone,
                    &.text-dni {
                        @extend .col-12;
                        padding-left: 0;
                        padding-right: 0;
                    }
                    &.text-firstname,
                    &.text-lastname,
                    &.text-postcode,
                    &.text-city,
                    &.text-siret,
                    &.text-vat_number,
                    &.text-phone_mobile {
                        @extend .col-6;
                    }
                    &.text-firstname,
                    &.text-postcode,
                    &.text-siret,
                    &.tel-phone {
                        padding-left: 0;
                    }
                    &.text-lastname,
                    &.text-city,
                    &.text-vat_number,
                    &.text-phone_mobile {
                        padding-right: 0;
                    }
                }
            }
            footer.form-footer {
                display: flex;
                justify-content: space-between;
                padding: 20px 98px;
                button.button,a.cancel-address {
                    width: 40%;
                    margin: 0;
                }
                a.cancel-address {
                    color: $color-yellow;
                    background: $color-duck-green;
                }
            }
        }
        .add-address {
            @include media-breakpoint-mobile {
                padding: 0;
            }
            .card {
                background: transparent;
                border: 1px solid $color-black;
                color: $color-duck-green;
            }
        }

    }
    #invoice-addresses {
        padding: 20px 98px;
    }
    .address-item {
        @include media-breakpoint-mobile{
            padding: 0;
        }
        .card {
            border: 1px solid $color-black;
            background: transparent;
            .card-header {
                border: none;
                background: transparent;
                .custom-control-label{
                    font-family: $font-title;
                    font-size: 17px;
                    line-height: 25px;
                    color: $color-duck-green;
                }
                .custom-control-input:checked ~ .custom-control-label::before {
                    border-color: $color-black;
                    background-color: transparent; 
                }
                .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
                    background-image: url(/themes/classic-rocket/assets/img/checked.svg);
                }
            }
            .card-body {
                font-size: 17px;
                line-height: 25px;
                padding-left: 68px;
                color: $color-black;
                @media (max-width: 992px){
                    padding-left: 20px;
                }
            }
            .card-footer {
                display: flex;
                justify-content: space-between;
                border: none;
                background: transparent;
                a {
                    color: $color-duck-green;
                }
                .edit-address {
                    span {
                        display: flex;
                        align-items: flex-start;
                        img {
                            margin-left: 9px;
                        }
                    }
                }
                .delete-address {
                    .hide-desktop {
                        display: block;
                        @include media-breakpoint-mobile {
                            display: none;
                        }
                    }
                    .hide-mobile {
                        display: none;
                        @include media-breakpoint-mobile {
                            display: block;
                        }
                    }
                    span {
                        display: flex;
                        align-items: flex-start;
                        img {
                            margin-right: 9px;
                        }
                    }
                }
            }
        }
    }
    .button__lg {
        border: none;
        margin: 0 auto;
        width: 30%;
        @media (max-width: 992px){
            width: 100%;
        }
    }
    .button {
        border: none;
        width: 90%;
        margin: 0 auto;
    }
    #checkout-payment-step{
        #modal {
            .modal-dialog {
                @media (min-width: 576px){
                    max-width: 750px;
                }
                .modal-content {
                    padding: 1.5em;
                }
            }
        }
        .title-description {
            font-family: $font-title;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: $color-duck-green;
            margin-bottom: 20px;
            &.space-md {
                margin-top: 30px;
            }
        }
        .payment-options {
            .payment-option {
                background: $color-grey;
                padding: 48px 30px;
                margin-bottom: 15px;
                .custom-control-label {
                    font-family: $font-title;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    @media (min-width: 550px) {
                        font-size: 20px;
                    }
                }
            }
        }
        .price_shipping {
            font-family: $font-title;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: $color-duck-green;
        }
        .address-shipping {
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            margin-top: 20px;
        }
        .button-link {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            border: none;
            background: none;
            padding: 0;
            margin-top: 18px;
        }
    }
    .order-confirmation-table-decoval {
        background: $color-grey;
        // padding-bottom: 18px;
        .order-confirmation-table {
            border: none;
            // padding: 0;
            padding: 25px 21px 18px 21px;
            .table{
                background: $color-grey;
                .subtotal {
                    font-family: $font-title;
                    font-weight: 700;
                    font-size: 16px;
                    color: $color-duck-green;
                    @media (min-width: 550px) {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
                .info {
                    border-bottom: 1px solid $color-black;
                    td {
                        font-family: $font-title;
                        font-weight: 500;
                        line-height: 25px;
                        color: $color-duck-green;
                    }
                }
                .info-borderless {
                    @extend .info;
                    border-bottom: 0;
                }
                .text-uppercase{
                    color: $color-green;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: $font-title;
                }
            }
        }
        .total-order {
            background: $color-duck-green;
            color: $color-white;
            padding: 9px 21px;
            display: flex;
            justify-content: space-between;
            font-family: $font-title;
            font-weight: 700;
        }
        #conditions-to-approve{
            padding: 15px 21px;
            .custom-control-label {
                font-size: 14px;
            }
        }
    }
}

.breadcrumb-header-decoval {
    width: 30%;
    .checkout-breadcrumb {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        .box-step{
           color: $color-yellow;
           font-weight: 700;
           font-size: 20px;
           line-height: 30px;
           background: $color-duck-green;
           text-align: center;
           padding: 0 9px;
           display: block;
           width: 30px;
           margin: 0 auto;
           z-index: 2;
        }
        a {
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: $color-duck-green;
        }
        &::after {
            content: '';
            width: 90%;
            z-index: -1;
            height: 3px;
            background: #e5e5e5;
            position: absolute;
            bottom: 75%;
            left: 10px;
        }
    }
}

.order-options-block {
    margin-top: 84px;
    @media (max-width: 992px){
        margin-top: 42px;
    }
    .block-title{
        font-family: $font-title;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: $color-duck-green;
        margin-bottom: 20px !important;
    }  
    .order-options{
        background: $color-grey;
        padding: 35px 31px;
        width: 100%;
        .addresshead {
            display: flex;
            align-items: baseline;
            .step-edit {
                display: flex;
                align-items: flex-end;
                .icon-svg {
                    width: 18px;
                    margin-left: 0.5em;
                }
            }
        }
        .card {
            background: transparent;
            border: none;
            .card-header {
                background: transparent;
                border: none;
                h4 {
                    font-family: $font-title;
                    font-weight: 700;
                    color: $color-green;
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .card-body {
                font-size: 17px;
                line-height: 25px;
                padding-top: 0;
                padding-bottom: 35px;
            }
        }
        .divider {
            border-bottom: 1.5px solid $color-duck-green;
        }
        #delivery {
            p {
                text-align: center;
                font-size: 16px;
                line-height: 25px;
                width: 50%;
                margin: 0 auto;
                font-family: $font-title;
                margin-bottom: 22px;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
            textarea {
                border-color: $color-black;
                width: 70%;
                margin: 0 auto;
                background: transparent;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
}

#blockcart-modal {
    .modal-dialog {
        position: fixed;
        margin: 0;
        right: 0;
        width: 35%;
        @media (max-width: 992px){
            width: 100%;
        }
        .modal-content{
            height: 100vh;
            display: block;
            @include media-breakpoint-mobile {
                display: flex;
            }
            .modal-header {
                border-bottom: none;
                .modal-title {
                    font-family: $font-title;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 35px;
                    color: $color-duck-green;
                    justify-content: start;
                }
            }
            .divider {
                border-bottom: 1px solid $color-duck-green;
                width: 95%;
                margin: 0 auto 1em auto;
            }
            .modal-body {
                .cart-content {
                    background: transparent;
                    padding: 0;
                    .subtotal {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 25px;
                    }
                    .product-count {
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 25px;
                    }
                    .product-price {
                        font-family: $font-title;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 23px;
                        color: $color-green;
                        margin-bottom: 0;
                    }
                }
                .media {
                    background: $color-grey;
                    margin-bottom: 1.5em;
                    padding: 16px;
                    @include media-breakpoint-mobile {
                        width: 100%;
                    }
                    .media-body {
                        align-self: center;
                        .product-name {
                            font-family: $font-title;
                            font-weight: 700;
                            font-size: 15px;
                            line-height: 40px;
                            color: $color-duck-green;
                            margin-bottom: 0;
                        }
                        .product-price {
                            font-family: $font-title;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 23px;
                            color: $color-green;
                            margin-bottom: 0;
                        }
                        .regular-price {
                            color: $color-duck-green;
                            text-decoration: line-through;
                            font-family: $font-title;
                            font-size: 15px;
                            margin-bottom: 0;
                        }
                    }
                    .modal-cart__image {
                        @include media-breakpoint-mobile {
                            max-width: 100px;
                        }
                    }
                }
            }
            .modal-footer {
                padding: 15px;
                border: none;
                @include media-breakpoint-mobile {
                    box-shadow: none;
                    position: initial;
                }
                button, a {
                    width: 100%;
                    padding: 15px 0;
                    font-weight: 600;
                    line-height: 18px;
                    color: $color-duck-green;
                    text-transform: uppercase;
                    font-family: $font-title;
                }
                button {
                    background: $color-grey;
                    border-color: $color-grey;
                }
                a {
                    background: $color-yellow;
                    border-color: $color-yellow;
                }
            }
        }
    }
}

#recap-box {
    gap: 20px;
}