.product-thumb-cross-selling {
    display: inline-block;
    &-item {
        background: $color-grey;
        padding: 40px;
        border: none;
        margin: 1em;
        &-img {
            width: 100%;
            height: auto;
            margin-bottom: 1.5em;
        }
    }
    &-body {
        margin-left: 0;
        padding: 0;
    }
}