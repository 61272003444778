.home-video {
    width: 100%;
    max-width: 100vw;
    height: 506px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &::before {
      display: block;
      content: '';
      padding-bottom: 56.25%;
    }
    @media (max-width: 992px){
        max-width: inherit;
        height: 230px;
    }
}
.home-video-play {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-image: url('/themes/classic-rocket/assets/img/decoval-servipack-presentation-entreprise.png');
    img {
        transition: .2s;
        @media (max-width: 992px) {
            height: 44px;
        }
    }
    &::before {
        transition: .2s;
        display: block;
        content: '';
        background-color: #252525;
        opacity: .5;
        cursor: pointer;
    }
    &:hover {
        &::before {
            opacity: .7;
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: none;
        z-index: 10;
    }
}
#product .home-video-play {
    background-image: url('/themes/classic-rocket/assets/img/video.jpg');
}
.home-video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

