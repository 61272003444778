@mixin media-breakpoint-desktop() {

  @include media-breakpoint-up(lg) {
    @content;
  }
}
@mixin media-breakpoint-mobile() {

  @include media-breakpoint-down(md) {
    @content;
  }
}

/** TITLE **/
@mixin title {
  font-family: $font-title;
  font-weight: 800;
  color: $color-duck-green;
}
