.page-item.active .page-link, .product-comments__pagination li.active .page-link, .page-item.active .product-comments__pagination li span, .product-comments__pagination li .page-item.active span, .product-comments__pagination li.active span{
    background-color: $color-duck-green;
    color: $color-yellow;
    border: none;
    margin: 0 4px;
}

.page-link, .product-comments__pagination li span {
    border: none;
    color: $color-black;
    background-color: $color-grey;
    margin: 0 4px;
}

a.page-link.next.js-search-link, a.page-link.previous.js-search-link {
    background: none;
}

ul.page-list.pagination {
    font-family: $font-title;
    font-weight: 700;
    font-size: 17px;
    line-height: 18px;
}

.row.u-a-i-c {
    margin-top: 50px;
}