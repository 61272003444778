#cart {
    #main {
        margin-top: 50px;
        @include media-breakpoint-mobile {
            margin-top: 15px;
        }
    }
    .promo-name {
        .cart-summary-line {
            gap: 1em;
            .label {
                color: $color-green;
            }
            .info-line-voucher {
                gap: 5px;
                span,
                .material-icons {
                    color: $color-green;
                }
            }
        }
    }
    .cart-grid-right {
        margin-top: 34px !important;
    }
    .cart-grid {
        .card {
            border: none;
            background-color: transparent;
            .card-header {
                border-bottom: none;
                background-color: transparent;
                text-transform: inherit;
                padding-left: 0;
            }
            .cart-item {
                background: $color-grey;
                padding: 16px;
                .media {
                    .product-line__img {
                        @include media-breakpoint-mobile {
                            width: 120px;
                        }
                    }
                    &-body {
                        .product-line__title{
                            font-family: $font-title;
                            font-weight: 800;
                            font-size: 17px;
                            line-height: 25px;
                            color: $color-duck-green;
                            margin-bottom: 10px;
                        }
                    }
                }
                .product-line-actions {
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 100%;
                    @include media-breakpoint-mobile {
                        flex-direction: initial;
                        align-items: center;
                    }
                }
                .icon-svg{
                    width: 18px;
                    height: 20px;
                }
                .icon-stock {
                    width: 16px;
                    height: 16px;
                }
                .regular-price {
                    float: right;
                }
                .info-reference {
                    font-family: $font-title;
                    font-weight: 500;
                    line-height: 14px;
                    margin-bottom: 1em;
                    span {
                        font-weight: 700;
                    }
                }
                .info-stock {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    line-height: 14px;
                    margin-bottom: 0.5em;
                    svg {
                        margin-right: 6px;
                    }
                    .instock {
                        font-family: $font-title;
                        font-weight: 700;
                        color: $color-green;
                    }
                    .outofstock {
                        font-family: $font-title;
                        font-weight: 700;
                        color: $color-red;
                    }
                }
            }
        }
        .title-recap {
            font-family: $font-title;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: $color-duck-green;
        }
        .cart-summary{
            .card-body {
                padding: 0;
            }
            background: $color-grey;
            // padding: 22px 21px 28px;
            .card-footer {
                padding: 0;
                background: transparent;
            }
            .block-promo {
                padding: 16px 21px;
                .promo-code__content {
                    background: transparent;
                }
                .input-group {
                    .form-control {
                        border: 1.5px solid $color-duck-green;
                        box-sizing: border-box;
                    }
                    .btn {
                        &-primary {
                            background: $color-duck-green;
                            color: $color-white;
                            border-color: transparent;
                            font-family: $font-title;
                            font-weight: 500;
                        }
                    }
                }
                .promo-code {
                    .collapse-button {
                        color: $color-duck-green;
                        font-family: $font-title;
                        font-weight: 700;
                    }
                }
                .promo-code-button{
                    text-align: left;
                    margin-bottom: 0;
                    a {
                        color: $color-duck-green;
                        font-family: $font-title;
                        font-weight: 500;
                        line-height: 14px;
                    }
                    .collapse-button {
                        display: flex;
                        justify-content: space-between;
                        &::after {
                            content: "+";
                            display: block;
                            font-size: 2em;
                        }
                        &[aria-expanded="false"]{
                            &::after {
                                content: "+";
                                display: block;
                                font-size: 2em;
                            }
                        }
                        &[aria-expanded="true"] {
                            &::after{
                                content: "-";
                                display: block;
                                font-size: 2em;
                            }
                        }
                    }
                }
            }
            .cart-summary-line{
                font-family: $font-title;
                font-weight: 500;
            }
            .cart-summary-totals{
                .cart-total{
                    background: $color-duck-green;
                    color: $color-white;
                    padding: 9px 21px;
                    font-family: $font-title;
                    font-weight: 700;
                }
            }
            .cart-detailed-totals{
                &-content{
                    padding: 22px 21px 10px;
                }
            }
        }
        .secure-payment-block{
            width: 100%;
            @include media-breakpoint-mobile {
                text-align: center;
            }
            &-title {
                font-family: $font-title;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: $color-duck-green;
            }
            img {
                width: 100%;
                @include media-breakpoint-mobile {
                    width: 100%;
                }
            }
        }
    }
    .cart-grid-right{
        margin-top: 55px;
    }
}