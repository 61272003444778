#category {
    .subcategories {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @include media-breakpoint-mobile {
            flex-direction: column;
        }
        .child-cat {
            width: 30%;
            background: $color-duck-green;
            text-align: center;
            padding: 1em 0;
            color: #ffffff;
            font-family: $font-title;
            line-height: 18px;
            font-weight: 600;
            @include media-breakpoint-mobile {
                width: 100%;
            }
            img {
                vertical-align: text-top;
                margin-left: 5px;
            }
        }
    }
    #amazzing_filter {
        background: #FBF8F6;
        margin: 0 30px 40px 30px;
        .af_filter {
            text-align: center;
            margin-bottom: 0;
            .af_subtitle{
                background: transparent;
                border: none;
                font-family: $font-title;
                font-size: 13px;
                line-height: 30px;
                font-weight: 600;
                color: $color-duck-green;
                &::before{
                    border-color: $color-duck-green;
                    right: 5px;
                }
                @include media-breakpoint-mobile {
                    display: flex;
                }
            }
        }
        .btn-holder {
            background: $color-duck-green;
            color: $color-yellow;
            text-align: center;
        }
        .compact-toggle {
            color: $color-duck-green;
        }
        @include media-breakpoint-mobile {
            margin: 0;
        }
    }
    .page-link {
        &:focus{
            box-shadow: none;
        }
        &:hover{
            background-color: $color-duck-green;
            color: $color-yellow;
        }
    }
    #category-description {
        width: 70%;
        font-weight: 400;
    }
    .under-category {
        background: $color-duck-green;
        padding: 16px 0;
        text-align: center;
        margin-bottom: 1em;
        a {
            color: $color-white;
            text-transform: uppercase;
            &::after {
                content: ">";
            }
        }
    }
    .block-category {
        border: 0px;
        background: $color-grey;
        min-height: 275px;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        
        #_desktop_category_header {
            .h1 {
                text-transform: capitalize;
            }
        }
        .breadcrumb {
            background: none;
        }
        .btn-category {
            width: fit-content;
            a {
                display: flex;
                border: 1.5px solid $color-duck-green;
            }
            .title-btn-cat {
                padding: 7px 11px 7px;
                color: $color-duck-green;
                font-family: $font-title;
                font-weight: 600;
            }
            .arrow {
                color: #ffffff;
                background: $color-duck-green;
                padding: 7px 11px 7px;
                border-top-width: 1.5px;
                border-right-width: 1.5px;
                border-bottom-width: 1.5px;
                border-color: $color-duck-green;
            }
        }
    }
    .product-category {
        .card {
            border: none;
        }
        .card-product {
            padding: 28px 33px 19px 33px;
            background: $color-grey;
            .card-body {
                padding: 0px;
                .product-title {
                    font-size: 17px;
                    line-height: 20px;
                    margin-top: 15px;
                    text-transform: capitalize;
                    margin-bottom: 34px;
                }
                .price {
                    font-size: 30px;
                    line-height: 30px;
                    margin-right: 8px;
                }
                .add {
                    margin-top: 22px;
                }
                .btn-add-to-cart {
                    text-transform: inherit;
                }
            }
        }
    }
    .description-xl {
        padding: 59px 130px 64px 145px;
        background: $color-grey;
        margin-top: 79px;
        @media (max-width: 992px){
            padding: 15px 21px 24px 20px;
        }
        p {
            font-weight: 300;
        }
        h2 {
            display: block;

            &::after{
                content: '';
                border: 5px solid $color-yellow;
                width: 153px;
                display: flex;
                margin: 24px 0 38px 0;
                @include media-breakpoint-mobile {
                    margin: 0.5em auto 1em auto;
                }
            }
        }
        h3 {
            display: block;
            text-align: initial;
            font-size: 25px;
            font-weight: 800;
            line-height: 31px;
            margin-bottom: 0.5em;
        }
    }
    .block-reassurance-title {
        text-transform: uppercase;
    }
}
