h1, .h1 {
    @include title;
    font-size: 45px;
    line-height: 51px;
    @media (max-width: 992px){
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
    }
    span {
        color: $color-yellow;
    }
}
h2, .h2 {
    @include title;
    font-size: 40px;
    line-height: 44px;
    font-weight: 700;
    position: relative;
    display: flex;
    justify-content: flex-start;
    span {
        border-bottom: 5px solid $color-yellow-dark;
        width: 153px;
        position: absolute;
        bottom: 0;
        @media (max-width: 992px){
            bottom: -5px;
        }
    }
    &__sm {
        font-size: 18px;
    }
    @media (max-width: 992px){
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        justify-content: center;
        font-weight: 800;
    }
}
h3, .h3 {
    @include title;
    font-size: 40px;
    line-height: 66px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: 992px){
        font-size: 25px;
        line-height: 30px;
    }
    span {
        border-bottom: 5px solid $color-yellow-dark;
        width: 153px;
        position: absolute;
        bottom: 0;
        @media (max-width: 992px){
            bottom: -5px;
        }
    }
    &__white {
        @extend .h3;
        font-size: 25px;
        line-height: 44px;
        color: #ffffff;
        &__sm {
            @extend .h3__white;
            line-height: 28px;
            text-align: left;
        }
    }
    &__align-left {
        @extend .h3;
        justify-content: left;
    }
}

.title {
    @include title;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 40px;
    &::after {
        content: '';
        border-top: 5px solid $color-yellow;
        width: 153px;
        display: flex;
        margin-top: 24px;
    }
    &-step {
        @include title;
        font-size: 45px;
        line-height: 66px;
        color: $color-duck-green;
        text-align: center;
        margin-bottom: 30px !important;
    }
}