#module-prestablog-blog {

    #list-blog-article {
        @media (max-width: 992px){
            gap: 1.5em;
        }
    }
    .title-interested-news {
        justify-content: center;
        font-size: 30px;
        line-height: 30px;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 55px;
        &::after {
            content: '';
            border-bottom: 5px solid $color-yellow;
            width: 153px;
            display: flex;
            position: absolute;
            bottom: 0;
        }
    }
    .decoval-blog-section {
        background: $color-grey;
        padding: 9px 16px 23px 27px;
        h3 {
            font-size: 25px;
            line-height: 33px;
            text-align: initial;
            margin-bottom: 10px;
        }
        p {
            text-align: initial;
        }
        .date_published {
            margin-bottom: 12px;
        }
        .link-article-blog-decoval {
            text-align: left;
            span {
                border-bottom: 2px solid $color-duck-green;
                padding-bottom: 7px;
                a {
                    color: $color-duck-green;
                    font-family: $font-title;
                    font-weight: 700;
                    line-height: 18px;
                    svg {
                        width: 1em;
                        height: 13px;
                        fill: $color-duck-green;
                    }
                }
            }
        }
    }
    .block-blog {
        background: url('/themes/classic-rocket/assets/img/blog.svg'), $color-grey;
        height: 275px;
        background-repeat: no-repeat;
        background-position: bottom right;
        @media (max-width: 992px){
            background-image: none;
            height: auto;
            padding: 15px 0;
        }
        .title-blog {
            @media (max-width: 992px){
                padding: 0 15px;
                // padding-top: 15px;
            }
        }
        // h1 {
        //     width: 50%;
        //     color: $color-duck-green;
        //     text-transform: capitalize;
        //     margin-bottom: 20px;
        //     @media (max-width: 992px){
        //         width: 100%;
        //         margin-top: 0;
        //         // padding: 0 1em;
        //     }
        // }
        .description {
            width: 50%;
            @media (max-width: 992px){
                width: 100%;
                padding: 0 1em;
            }
        }
    }
    .breadcrumb {
        padding: 0;
    }
    div.prestablog_pagination span.current{
        background: $color-duck-green;
        color: $color-yellow;
        border: none !important;
        font-family: $font-title;
        font-weight: 700;
    }
    div.prestablog_pagination a {
        background: $color-grey;
        color: $color-black;
        padding: 5px 10px;
        font-family: $font-title;
        font-weight: 700;
    }
    div.prestablog_pagination .arrow-next {
        background: transparent;
        svg {
            width: 15px;
            height: 13px;
            fill: $color-duck-green;
        }
    }
    div.prestablog_pagination .arrow-prev {
        background: transparent;
        svg {
            transform: rotate(180deg);
            width: 15px;
            height: 13px;
            fill: $color-duck-green;
        }
    }
    .pagination-post {
        display: flex;
        justify-content: center;
    }
    .block-title {
        min-height: 255px;
        margin-bottom: 77px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center right !important;
        background-color: #ffffff !important;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
        @media (max-width: 992px){
            background: none !important;
            margin-bottom: 35px;
        }
        .title {
            font-size: 55px;
            line-height: 55px;
            font-family: $font-title;
            color: #01247C;
            margin-bottom: 25px;
            font-weight: 700;
        }
        .description {
            p {
                font-family: $font-text;
                font-weight: 400;
                line-height: 23px;
                color: #01247C;
                width: 50%;
                @media (max-width:992px){
                    width: 100%;
                }
            }
        }
    }

    #prestablogfont {
        @media (max-width: 992px){
            padding: 1em;
            margin-top: 30px;
        }
    }

    #prestablogfront,
    .prestablogExtra {
        border: none;
        padding: 0;
    }

    #prestablogfront {

        p {
            font-family: $font-text !important;
        }
        .blog-img-post {
            height: 224px;
            margin-bottom: 50px;
            position: relative;
            background: $color-grey;
            @media (max-width: 992px) {
                margin-bottom: 0px;
                padding: 1em;
                height: auto;
            }

            nav {
                .breadcrumb {
                    .breadcrumb-item {
                        a {
                            color: $color-duck-green;
                        }

                        &.active {
                            color: $color-duck-green;
                        }
                    }
                }
            }

            .block-share {
                display: flex;
                justify-content: center;

                .social-share {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFFFFF;
                    padding: 14px 0;
                    width: 328px;
                    border-radius: 100px;
                    position: absolute;
                    bottom: -25px;
                    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);

                    div {
                        margin-left: 16px;
                    }

                    p {
                        margin-bottom: 0;
                        font-family: $font-text;
                        color: #01247C;
                        font-size: 16px;
                        line-height: 19.36px;
                        font-weight: 600;
                    }

                    svg {
                        height: 27px;
                        width: 27px;
                        margin-right: 14px;
                    }
                }
            }
        }

        time.date {
            font-style: normal;
            font-size: 16px;
            line-height: 25px;
            color: $color-black;
            font-family: $font-text;
            font-weight: 500;
            text-align: left;
            margin: 0;
            &:before {
                border-top: unset;
            }
            span {
                padding: 0;
                background: transparent;
            }
        }

        #prestablog_article {
            text-transform: initial;
            margin: 15px 0 40px 0;
            color: $color-duck-green;
            font-weight: 800;
            font-family: $font-title;
            font-size: 45px;
            line-height: 51px;
            @media (max-width: 992px){
                margin-bottom: 0;
            }
            &::after {
                display: none;
            }
        }

        .page-header {
            background-color: unset;
            border-bottom: unset;
            text-align: center;
            margin-bottom: 24px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            border-top: none;
            border-bottom: none;
            text-transform: initial;
            margin: 0;
            display: initial;
            // padding-top: unset;
            // padding-bottom: 8px;
            // margin: unset;
            // font-family: $font-title !important;
            // color: $color-duck-green;

            @media (max-width: 720px) {
                padding-bottom: 4px;
            }
        }
        
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 30px;
            @include media-breakpoint-mobile {
                font-size: 20px;
            }
        }


        // h1 {
        //     font-weight: 800;
        //     font-size: 45px;
        //     line-height: 51px;
        //     font-family: $font-title;
        //     color: $color-duck-green;
        //     text-transform: capitalize;
        //     padding-bottom: 14px;
            // &::after {
            //     content: '';
            //     border-bottom: 5px solid $color-yellow;
            //     width: 153px;
            //     display: flex;
            //     position: absolute;
            //     bottom: 0;
            // }
        // }

        // h2 {
        //     font-size: 40px;
        //     font-weight: 900;
        //     line-height: 40px;
        //     position: relative;
        //     padding-bottom: 14px;
        //     justify-content: initial;

        //     @media (max-width: 720px) {
        //         font-size: 22.5px;
        //         line-height: 22.5px;
        //     }
            // &::after {
            //     content: '';
            //     border-bottom: 5px solid $color-yellow;
            //     width: 153px;
            //     display: flex;
            //     position: absolute;
            //     bottom: 0;
            // }
        // }

        // h3 {
        //     font-size: 30px;
        //     font-weight: 900;
        //     line-height: 30px;
        //     color: $color-duck-green;
        //     padding-bottom: 24px;
        //     @media (max-width: 720px) {
        //         font-size: 19px;
        //         line-height: 18px;
        //     }
            // &::after {
            //     content: '';
            //     border-bottom: 5px solid $color-yellow;
            //     width: 153px;
            //     display: flex;
            //     position: absolute;
            //     bottom: 0;
            // }
        // }

        // h4 {
        //     font-size: 18px;
        //     font-weight: 500;
        //     line-height: 18px;
        // }

        // h5 {
        //     font-size: 16px;
        //     font-weight: 500;
        //     line-height: 16px;
        // }

        // h6 {
        //     font-size: 15px;
        //     font-weight: 500;
        //     line-height: 15px;
        // }

        .elementor-widget {
            margin-bottom: 20px;
        }

        ul:not(.elementor-icon-list-items) {
            list-style: unset;
            padding-left: 18px;
        }
    }
}

.home-blog-item {
    position: relative;
    overflow: hidden;

    &::before {
        display: block;
        content: '';
        background: rgb(45, 35, 33);
        background: linear-gradient(0deg, rgba(45, 35, 33, 1) 0%, rgba(45, 35, 33, 0) 100%);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::after {
        content: '';
        display: block;
        padding-bottom: 75%;
    }

    h3 {
        font-size: 20px;
        color: #fff;
        line-height: 24px;
        margin-bottom: 17px;
    }

    .blog-read-article {
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        position: relative;

        i {
            font-size: 9px;
        }

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            background: #fff;
            bottom: -4px;
            left: 0;
        }
    }

    .home-blog-item-content {
        position: absolute;
        left: 24px;
        right: 24px;
        bottom: 24px;
    }

    img {
        position: absolute;
        left: 15px;
        top: 0;
        z-index: -1;
        width: calc(100% - 30px);
    }

    @media (max-width: 767px) {
        margin: 0 auto 16px;
    }
}

// #module-prestablog-blog h1 {
//     color: #212529;
//     margin-bottom: 57px;
// }

.prestablog_menu_cat {
    background: #FBFBFB;
    height: 39px;
    padding: 8px 0;
    box-sizing: border-box;
    margin-bottom: 100px;

    ul {
        margin-bottom: 0;
    }

    a,
    i {
        line-height: 22px;
        font-size: 13px;
    }

    a {
        color: #000;
        margin-right: 52px;
    }
}

#blog_list_1-7 {
    margin-bottom: 110px;

    .blog-grid>.block_cont {
        background: #fff;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .04);
        overflow: hidden;
        margin-top: 38px;
    }

    .block_bas {
        padding: 9px 16px 23px 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: $color-grey;
        @media (max-width: 992px){
            justify-content: initial;
            height: auto !important;
        }
        h2 {
            text-align: initial;
        }
    }

    .prestablog_desc {
        font-size: 15px;
        line-height: 25px;
        font-family: $font-text;
        font-weight: 400;
        color: $color-black;
        margin: 0;
        margin-bottom: 20px;
    }

    .blog-article-date {
        font-size: 14px;
        line-height: 25px;;
        font-weight: 500;
        font-family: $font-text;
        color: $color-black;
        margin: 0;
    }

    h2 {
        font-size: 25px;
        line-height: 33px;
        font-weight: 800;
        // text-align: left;
        margin-bottom: 10px;
        font-family: $font-title;
        color: $color-duck-green;
    }

    .link-news {
        border-bottom: 2px solid $color-duck-green;
        padding-bottom: 7px;
    }

    a {
        font-size: 15px;
        line-height: 18px;
        color: $color-duck-green;
        font-weight: 700;
        position: relative;
        font-family: $font-title;
        // text-decoration: underline;

        svg {
            height: 18px;
            width: 18px;
            fill: $color-duck-green;
        }

        @media (max-width: 991px) {
            h2 {
                font-size: 18px;
                line-height: 20px;
                font-weight: 500;
            }

            a {
                font-size: 14px;

                i {
                    font-size: 10px;
                }
            }

            .prestablog_desc {
                font-size: 14px;
                line-height: 23px;
                font-weight: 300;
            }

            .blog-article-date {
                font-size: 13px;
                line-height: 1;
                margin-top: 0;
            }
        }

        // #prestablogfront h1 {
        //     font-size: 26px;
        //     line-height: 30px;
        //     margin-bottom: 0;
        // }
    }
}
