body{
  background-color: $color-white;
  font-family: $font-text;
  font-size: 15px;
}
a{
  text-decoration: none;
  color: $color-yellow;
  &:hover {
    color: $color-yellow;
  }
}
sup{
  top:-0.3em;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: 0;
  padding-top: 60px;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  // box-shadow: $bs-touchspin-boxshadow;
  // border:$input-border-width solid $input-border-color;
  font-family: $font-title;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$white;
    background-color: $color-duck-green;
    font-size: 25px;
    line-height: 14px;
    font-weight: 600;
  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
  #quantity_wanted{
    background: $color-grey;
    font-weight: 600;
    font-size: 20px;
    line-height: 14px;
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: 28px 33px;
  margin-bottom: $spacer;
  background-color: $color-grey;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border-color: $color-black;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(/themes/classic-rocket/assets/img/checked.svg);
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(/themes/classic-rocket/assets/img/checked-green.svg);
  background-size: contain;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-family: $font-title;
  font-weight: 700;
  font-size: 17px;
  line-height: 19px;
  color: $color-duck-green;
}
  .carrier-price{
    font-family: $font-title;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: $color-green;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.link {
  font-family: $font-title;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  &:hover {
    color: $color-yellow;
    border-color: $color-yellow;
    svg {
      fill: $color-yellow;
    }
  }
  &__white {
    @extend .link;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
    span {
      border-bottom: 1.5px solid #ffffff;
      padding-bottom: 5px;
    }
  }
}

.container-custom {
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

p {
  @media (max-width: 992px){
    font-size: 15px;
  }
}

// .desktop {
//   @media (max-width: 992px) {
//     display: none;
//   }
// }

// // .mobile {
// //   display: none !important;
// //   @media (max-width: 992px){
// //     display: block;
// //   }
// // }

.btn-link {
  color: $color-duck-green;
}

.btn-back-account {
  font-family: $font-title;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 21px;
  a {
    color: $color-black;
  }
}

.carrier-notice {
  background: $color-grey;
  padding: 0 31px 21px;
  margin-top: -1rem;
  .divider {
    margin-bottom: 28px;
    border-bottom: 1.5px solid $color-duck-green;
  }
  .titre {
    margin-bottom: 21px !important;
    line-height: 25px;
    color: #E14300;
    font-weight: 700;
    @media (max-width: 992px){
      margin-top: 1.5em;
      margin-bottom: 10px !important;
    }
  }
  a {
    color: #212529;
    font-weight: 600;
    text-decoration: underline;
  }
  ul {
    list-style: initial;
    padding-left: inherit;
  }
  .content {
    line-height: 25px;
    .tag {
      line-height: 20px;
      font-family: $font-title;
      @media (max-width: 992px){
        font-size: 13.5px;
      }
      img {
        margin-right: 18px;
        @media (max-width: 992px){
          margin-right: 9px;
          width: 2em;
        }
      }
    }
  }
}
.bkg-card{
  background: $color-grey;
  &.padding {
    padding: 22px 28px;
  }
  &.padding-16{
    padding: 16px;
  }
  .image {
    img {
      width: 147px;
    }
  }
  .info-products-cart {
    margin-left: 21px;
    @media (max-width: 992px){
      margin-top: 1em;
      margin-left: 0;
    }
    .name {
      font-family: $font-title;
      font-weight: 800;
      font-size: 17px;
      line-height: 25px;
      color: $color-duck-green;
      margin-bottom: 10px !important;
      @include media-breakpoint-mobile{
        font-size: 15px;
        line-height: 17px;
      }
    }
    .ref {
      font-family: $font-title;
      font-size: 15px;
      line-height: 14px;
      margin-bottom: 10px !important;
      span {
        font-weight: 700;
      }
    }
    .qty {
      font-weight: 700;
      line-height: 14px;
      @media (max-width: 992px){
        text-align: left;
      }
    }
  }
  .price-products-cart{
    .price {
      font-family: $font-title;
      font-weight: 800;
      font-size: 30px;
      line-height: 30px;
      color: $color-green;
      @media (max-width: 992px){
        margin-top: 0.5em;
      }
    }
  }
}
.row, .products {
  margin-right: 0px;
  margin-left: 0px;
}

#cms {
  #main {
    &.page-wrapper {
      border: none;
      background: transparent;
      margin-top: 1em;
      .page-content {
        @include media-breakpoint-mobile {
          padding: 0;
        }
      }
    }
    h3 {
      display: block;
    }
  }
}

#iframe_devis {
  height: 500px;
  @media (max-width: 425px) {
      height: 550px;
  }
  @media (max-width: 370px) {
    height: 600px;
  }
  @media (max-width: 305px) {
    height: 650px;
  }
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-width: 3px;

}