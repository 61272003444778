#category-3 {
    a {
        @media (max-width: 992px){
            display: flex;
            &:before {
                content: url(/themes/classic-rocket/assets/img/presse-balle.svg);
                margin-right: 20px;
            }
        }
    }
}
#category-6 {
    a {
        @media (max-width: 992px){
            display: flex;

            &:before {
                content: url(/themes/classic-rocket/assets/img/tasseur.svg);
                margin-right: 20px;
            }
        }
    }
}
#category-9 {
    a {
        @media (max-width: 992px){
            display: flex;

            &:before {
                content: url(/themes/classic-rocket/assets/img/consommables.svg);
                margin-right: 20px;
            }
        }
    }
}
.menu__item-header {
    align-items: center;
}
.menu__item-link--top {
    font-family: $font-title;
    font-weight: 700;
    font-size: 15px;
    line-height: 17.61px;
    text-transform: inherit;
    color: $color-white;
    @media (max-width: 992px){
        color: $color-black;
        font-weight: 600;
        font-size: 18px;
        line-height: 21.13px;
    }
}

.modal.fade .modal-dialog__offcanvas {
    width: 100%;
    max-width: 100%;
}

#top-nav-mobile-p {
    height: 76px;
    background: $color-green;
    display: flex;
    align-items: center;
    justify-content: center;
}
#top-nav-mobile-top-menu {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 300px - 56px);
}