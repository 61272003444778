.breadcrumb {
    background: none;
    font-size: 12px;
    line-height: 35px;
    color: $color-duck-green;
    margin-bottom: 0px;

    display: flex;
    flex-wrap: nowrap;
    padding: 0 8px 0 0;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
    &-item {
        display: inline-block;
        a {
            color: $color-duck-green;
        }
        &+.breadcrumb-item {
           &::before {
               content: ">";
               color: $color-duck-green;
               float: unset;
           }
        }
        &.active {
            font-weight: 700;
            color: $color-duck-green;
        }
    }
}
