.fake-slider{
    &-content {
        display: flex;
        justify-content: space-between;
        @media (max-width: 992px){
            flex-direction: column;
        }
        img{
            width: 100%;
        }
        &-title {
            align-self: center;
        }
        &-gif {
            width: 80%;
            @media (max-width: 992px){
                width: 100%;
            }
        }
    }
    .boxes-slide {
        display: flex;
        justify-content: space-between;
        position: relative;
        @media (max-width: 992px){
            flex-direction: column;
        }
        .box-slide {
            background: $color-grey;
            padding: 26px 33px 21px 33px;
            width: 48%;
            @media (max-width: 992px){
                width: 100%;
                margin-top: 20px;
            }
            p {
                font-size: 15px;
            }
        }
    }
    
}
.section-boxes {
    position: relative;
    margin-top: -150px;
    #carton {
        position : relative;
        bottom: -105px;
        left: -76px;
    }
    #box {
        position: relative;
        bottom: -80px;
        right: -150px;
    }
    #box-rotate {
        position: relative;
        right: -280px;
        bottom: -20px;
    }
    @media (max-width: 993px){
        img {
            display: none;
        }
        margin-top: 0;
    }
}
