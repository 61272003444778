.block-reassurance {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 54px;
    &-title {
        font-family: $font-title;
        font-weight: 700;
        font-size: 17px;
        line-height: 25px;
        margin: 20px 0;
        color: $color-duck-green; 
        text-align: center;
    }
    img {
        width: 100px;
        height: 100px;
    }
    &-content {
        font-size: 15px;
        text-align: center;
        @media (max-width: 992px) {
            display: none;
        }
    }
}

.block-user-top {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-family: $font-title;
    line-height: 16px;
    color: $color-duck-green;
    @include media-breakpoint-mobile {
        gap: 0;
    }
    &-title {
        line-height: 16px;
        font-size: 14px;
        font-weight: 700;
    }
    &-txt {
        font-size: 12px;
        font-weight: 400;
    }
}

.team-content{
    display: grid;
    overflow-x: auto;
    grid-auto-flow: column;
    grid-auto-columns: minmax(336px, 1fr);
    grid-gap: 30px;
    grid-auto-rows: minmax(352px, auto);
    margin-bottom: 80px;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 480px){
        grid-gap: 19px;
    }
    &-block {
        grid-row: 1;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }
    &-bloc1 {
        grid-row: 1;
        background-image: url(/themes/classic-rocket/assets/img/presse-a-balle-1.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }
    &-bloc2 {
        grid-row: 1;
        background-image: url(/themes/classic-rocket/assets/img/presse-a-balle-2.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }
    &-bloc3 {
        grid-row: 1;
        background-image: url(/themes/classic-rocket/assets/img/presse-a-balle-3.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }
    .layer {
        background: $color-duck-green;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .content {
        position: absolute;
        z-index: 2;
        padding: 41px 30px;
        bottom: 0;
        svg {
            width: 9px;
            height: 15px;
            margin-left: 10px;
        }
    }
}

.discovery-decoval {
    position: relative;
    bottom: -50px;
    z-index: 2;
    @media (max-width: 992px) {
        bottom: 0;
        margin-bottom: 25px;
    }
    &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
            padding-bottom: 21px;
            width: 80%;
            font-weight: 800;
            @media (max-width: 992px){
                width: 100%;
            }
        }
        a {
            margin-top: 35px;
        }
    }
    &-content {
        background: $color-duck-green;
        color: $color-white;
        padding: 60px 40px 41px 40px;
        .h3__white {
            justify-content: flex-start;
            margin-bottom: 29px;
            font-size: 23px;
        }
        p {
            span {
                color: $color-yellow;
            }
        }
        @media (max-width: 992px){
            margin: 25px 20px;
            padding: 35px 24px 40px 24px;
        }
    }
}

#index {
    .reassuraance-content-block {
        margin-bottom: 50px;
    }
}
