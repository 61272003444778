.top-bar {
    font-family: $font-title;
    display: flex;
    justify-content: space-between;
    p {
        margin-bottom: 0;
        line-height: 2;
        span {
            font-weight: 700;
            font-size: 12px;
            line-height: 14.09px;
            color: $color-duck-green;
            @media (max-width: 992px){
                display: none;
            }
        }
        a {
            font-weight: 800;
            font-size: 17px;
            line-height: 19.26px;
            color: $color-green;
            @media (max-width: 992px){
                color: $color-white;
                display: flex;
                &::before {
                    content: url(/themes/classic-rocket/assets/img/phone.svg);
                    margin-right: 15px;
                }
            }
        }
    }
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        li {
            border-left: 1.5px solid $color-duck-green;
            font-weight: 700;
            font-size: 14px;
            line-height: 16.44px;
            color: $color-duck-green;
            padding: 0 1em;
            text-align: center;
            position: relative;
            &:first-child {
                border-left: 0;
            }
            a {
                color: $color-duck-green;
            }
            @media (max-width: 992px){
                font-weight: 500;
                font-size: 18px;
                line-height: 21.13px;
                color: $color-black;
                border-left: 0px;
                padding: 17px 32px;
                text-align: left;
                border-bottom: 1px solid $color-grey-bar;
                width: 100%;
            }
        }
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
            background: $color-grey;
        }
    }
    @media (max-width: 992px) {
        display: block;
    //     // position: absolute;
    //     bottom: 0;
        width: 100%;
    }
}

.modal-body {
    padding: 0;
    position: relative;
}

#top-nav-mobile {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.header-nav.u-bor-bot {
    background: $color-grey;
    border-bottom: 0;
}


.decoval-header-top{
    position: relative;
    @media (max-width:992px){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo-header-top{
        display: flex;
        justify-content: center;
        @media (max-width:992px){
            display: block;
        }
        a {
            text-align: center;
        }
    }
    .actions-header-top {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 25%;
        right: 0;
        gap: 1em;
        @media (max-width:992px){
            position: relative;
            justify-content: inherit;
            gap: 0.5em;
        }
    }
}