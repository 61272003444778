#product {
    .images-container {
        .product-video-thumb {
            @include media-breakpoint-mobile {
                display: none;
            }
        }
    }

    @media (min-width: 992px) {
        .btn.product-video-thumb {
            display: none;
        }
    }

    #contact {
        .customer-service-content-phone {
            svg { width: 40px; }
            p {
                @include media-breakpoint-mobile {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
        .customer-service-content-hours {
            p { margin-bottom: 0; }
        }
    }
    .player {
        position: absolute;
        z-index: 1;
        width: 22px;
        height: 26px;
        top: 40%;
        left: 40%;
    }
    .video_product_trailer {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10000;
        background: rgba(0,0,0,0.85);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        iframe {
            position: relative;
            max-width: 900px;
            outline: none;
            width: 100%;
            height: 50vh;
        }
        &.active {
            visibility: visible;
            opacity: 1;
        }
        .close_product_video {
            color: #ffffff;
            font-size: 2.5em;
            position: absolute;
            cursor: pointer;
            top: 30px;
            right: 30px;
        }
    }
    .carrier-notice .divider {
        border: none;
        padding-top: 28px;
        margin-bottom: 0;
    }
    .vertical-center {
        margin: auto 0;
    }
    .page-product-box {
        background-color: transparent;
        border: none;
        margin-top: 0;
        padding: 0;
        @media (max-width: 992px){
            float: initial;
        }
        .questions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-weight: 700;
                color: $color-duck-green;
                font-size: 17px;
                padding-left: 1em;
                @include media-breakpoint-mobile {
                    width: 85%;
                }
            }
        }
        .answer_faq {
            box-shadow: none;
            background-color: transparent;
            border: none;
            padding: 0 0 0 1em;

        }
    }
    .dont-collapse-lg {
        @media (min-width: 992px) {
            display: block;
            height: auto !important;
            visibility: visible;
        }
    }
    #content {
        .product-thumbs {
            .slick-prev,.slick-next {
                @media (max-width: 374px){
                    display: none;
                }
            }
            .slick-arrow {
                @media (max-width: 425px) {
                    display: none !important;
                }
            }
        }
    }
    .title-block {
        display: flex;
        justify-content: space-between;
        .icon-svg {
            transition: transform .2s ease-in-out;
        }
        &[data-toggle='collapse'][aria-expanded='true']{
            .icon-svg {
                transform: rotate(-180deg);
            }
        }
        @media(max-width: 992px) {
            margin-bottom: 26px;
        }
        .title{
            @media (max-width: 992px)
            {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 0;
                &::after {
                    display: none;
                }
            }
            &.desktop {
                display: block;
                @media (max-width: 992px){
                    display: none;
                }
            }
            &.mobile {
                display: none;
                @media (max-width: 992px){
                    display: block;
                }
            }
        }
    }
    h1,.h1 {
        font-size: 30px;
        line-height: 40px;
        text-transform: capitalize;
    }
    .product-prices {
        &-details {
            display: flex;
            flex-direction: column;
        }
    }
    .separator {
        margin: 60px 0;
        @media(max-width: 992px){
            margin: 20px 0px;
            border: 1.5px solid $color-yellow-dark;
            background: $color-yellow-dark;
        }
    }
    .product-minimal-quantity{
        margin-bottom: 0;
    }
    .add {
        margin-top: 22px;
    }
    .box-details {
        &.store-details {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 8px 0px;
            span {
                color: $color-green;
                font-size: 12px;
                line-height: 18px;
            }
        }
        &.expedition-details {
            padding: 18px 0 14px 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            display: flex;
            justify-content: center;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
            }
            .icon-svg {
                width: 44px;
                height: 39px;
                margin: auto 16px auto 8px;
            }
        }
        background: $color-grey;
        font-family: $font-title;
        color: $color-duck-green;
        margin-top: 15px;
        p {
            margin-bottom: 0;
        }
    }

    .block-features {
        margin-top: 30px;
        .feature {
            margin-bottom: 20px;
            padding: 0;
            svg {
                height: 50px;
                width: 50px;
                @media (max-width: 992px){
                    height: 38px;
                    width: 38px;
                }
            }
            .pipe {
                border-left: 1.5px solid $color-duck-green;
                margin: 0 15px;
                @media (max-width: 992px){
                    margin: 0 9px;
                }
            }
            .product-feature-name{
                font-size: 15px;
                line-height: 20px;
                @media (max-width: 992px){
                    font-size: 13px;
                    line-height: 18px;
                }
            }
            .product-feature-value{
                font-family: $font-title;
                color: $color-green;
                font-size: 15px;
                line-height: 20px;
                font-weight: 700;
                @media (max-width: 992px){
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    }
    .description-short-product {
        p {
            span {
                font-weight: 400 !important;
                font-size: 15px !important;
                line-height: 24px !important;
                color: $color-black;
            }
        }
    }
    .product-menu {
        background: $color-grey;
        font-size: 13px;
        line-height: 20px;
        font-weight: 800;
        font-family: $font-title;
        margin-top: 17px;
        margin-bottom: 60px;
        @media (max-width: 992px){
            display: none;
        }
        &-list {
            display: flex;
            justify-content: space-between;
            li {
                width: 12.5%;
                text-align: center;
                padding: 13px 0;
                a {
                    color: $color-duck-green;
                }
                &:hover {
                    background: $color-duck-green;
                    a {
                        color: $color-yellow;
                    }
                }
            }
        }
    }
    .shipping-block {
        .carriers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 60px;
            .carrier {
                background: $color-grey;
                width: 31%;
                text-align: center;
                padding-top: 34px;
                font-family: $font-title;
                font-size: 20px;
                line-height: 25px;
                position: relative;
                img {
                    margin-bottom: 31px;
                }
                p {
                    margin-bottom: 0;
                    font-weight: 700;
                }
                span {
                    font-weight: 500;
                }
                .content {
                    margin-bottom: 33px;
                }
                .price {
                    span {
                        background: $color-duck-green;
                        padding: 16px 38px;
                        font-size: 25px;
                        line-height: 25px;
                        font-weight: 700;
                        color: $color-white;
                        position: relative;
                        bottom: -10px;
                    }
                }
            }
        }
    }
    .description-product{
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        ul {
            list-style: url('/themes/classic-rocket/assets/img/puce.svg');
            margin-left: 15px;
        }
        margin-bottom: 60px;
        @media (max-width: 992px){
            margin-bottom: 0px;
        }
    }
    .customer-service {
        &-content {
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 83px 67px;
            background: $color-grey;
            @media (max-width: 992px){
                display: block;
                padding: 25px;
            }
            &-title {
                width: 25%;
                .title {
                    margin-bottom: 0;
                }
                @media (max-width: 992px){
                    display: none;
                }
            }
            &-phone {
                svg {
                    width: 68px;
                    height: 68px;
                    margin-right: 30px;
                }
                p {
                    font-family: $font-title;
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 47px;
                    color: $color-duck-green;
                    @media (max-width: 992px){
                        font-size: 34px;
                        line-height: 40px;
                    }
                    span {
                        font-family: $font-text;
                        font-size: 17px;
                        line-height: 24px;
                        color: $color-black;
                        font-weight: 400;
                    }
                }
            }
            &-hours {
                width: 30%;
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: $color-black;
                    span {
                        font-weight: 700;
                    }
                }
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
    .technical-characteristics {
        .certifications {
            margin-top: 22px;
            display: flex;
            justify-content: space-around;
        }
        &-list {
            li {
                border-bottom: 1.5px solid #e7e7e7;
                p {
                    font-size: 15px;
                    line-height: 30px;
                    margin-bottom: 0;
                }
            }
        }
        .attachment {
            margin-top: 41px;
            a {
                width: 40%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
    #maintenance {
        .maintenance-content {
            background: $color-grey;
            padding: 41px 97px;
        }
    }
    .howtowork {
        position: relative;
        min-height: 344px;
        @include media-breakpoint-mobile {
            min-height: initial;
        }
        &-content-block {
            display: block;
            .howtowork-content {
                width: 100%;
                padding: 28px 23px;
                background: $color-duck-green;
                font-weight: 300;
                line-height: 24px;
                color: $color-white;
                cursor: pointer;
                @media (min-width: 1200px){
                    width: 559px;
                    padding: 30px 107px 30px 34px;
                }
            }
            @media (min-width: 992px){
                display: flex;
            }
        }
        &-home-video {
            width: 100%;
            position: relative;
            height: 344px;
            top: 0;
            right: 0;
            @media (min-width: 1200px) {
                width: 611.56px;
                position: absolute;
            }
        }
    }
}
