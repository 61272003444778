.main-menu-desktop {
    background-color: $color-duck-green;
    height: 46px;
    .menu {
        padding-bottom: 0;
        height: 100%;
        #top-menu {
            margin-bottom: 0;
            a {
                &:hover {
                    color: $color-yellow;
                }
            }
        }
    }
    @media (max-width: 992px){
        display: none;
    }
}