#contact {
    .page-wrapper {
        border: none;
        background: transparent;
    }
    .breadcrumb {
        padding: 0px;
    }
    .page-content {
        padding: 0;
        form {
            width: 100%;
            max-width: 100%;
        }
    }
    h3 {
        display: none;
    }
    .separator {
        margin-left: 0;
        margin-right: 0;
    }
    .block-title {
        background: $color-grey;
        height: 275px;
        border: 0;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        &-title {
            font-family: $font-title;
            font-weight: 800;
            font-size: 45px;
            line-height: 66px;
            color: $color-duck-green;
        }
        .container {
            p {
                width: 50%;
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
    form {
        margin-top: 55px;
        .ets_cfu_wrapper {
            width: 75%;
            margin: 0 auto;
            @media (max-width: 992px){
                width: 100%;
            }
        }
        label {
            span {
                font-family: $font-title;
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                color: $color-duck-green;
            }
        }
        .ets_cfu_input_acceptance.ets_cfu_input {
            display: flex;
            align-items: baseline;
        }
        .ets_cfu_help_block {
            font-style: normal;
            color: $color-black;
            font-size: 14px;
            line-height: 20px;
        }
        .button {
            margin: 0 auto;
            padding-top: 15px;
            padding-bottom: 15px;
            border-color: transparent;
            width: 50%;
            font-weight: 700;
            text-transform: capitalize;
            font-size: 15px;
        }
        .form-control {
            border: 1px solid $color-duck-green;
        }
    }
    .customer-service {
        &-content {
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 40px 32px;
            background: $color-grey;
            @media (max-width: 992px){
                display: block;
                padding: 25px;
            }
            &-title {
                width: 25%;
                .title {
                    margin-bottom: 0;
                }
                @media (max-width: 992px){
                    display: none;
                }
            }
            &-phone {
                svg {
                    width: 68px;
                    height: 68px;
                    margin-right: 30px;
                }
                p {
                    font-family: $font-title;
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 47px;
                    color: $color-duck-green;
                    margin-bottom: 0;
                    @media (max-width: 992px){
                        font-size: 34px;
                        line-height: 40px;
                    }
                    span {
                        font-family: $font-text;
                        font-size: 17px;
                        line-height: 24px;
                        color: $color-black;
                        font-weight: 400;
                    }
                }
            }
            &-hours {
                width: 30%;
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: $color-black;
                    span {
                        font-weight: 700;
                    }
                }
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
}