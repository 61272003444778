#help {
    .page-wrapper {
        background: transparent;
        border: none;
        .page-content {
            padding: 0;
            .separator {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .breadcrumb {
            padding: 0;
        }
    }
    .header-page {
        background: url('/themes/classic-rocket/assets/img/blog.svg'), $color-grey;
        background-repeat: no-repeat;
        height: 275px;
        background-position: bottom right;
        @media (max-width: 992px){
            background-image: none;
            height: auto;
            padding: 1em;
        }
        h1 {
            text-transform: capitalize;
            @media (max-width: 992px) {
                margin-top: 0;
            }
        }
        p {
            width: 50%;
            @media (max-width: 992px){
                width: 100%;
            }
        }
    }

    .block_cms_cat {
        margin-top: 50px;
        h2 {
            justify-content: initial;
        }
        a {
            font-family: $font-title;
            font-weight: 700;
            font-size: 17px;
            line-height: 31px;
            color: $color-black;
            &:hover {
                color: $color-green;
            }
        }
    }
}