#order-confirmation {
    h3.h1 {
        text-transform: initial;
        margin-top: 50px;
        margin-bottom: 0;
        @include media-breakpoint-mobile {
            margin-top: 25px;
        }
    }
    .bg-grey {
        background: $color-grey;
        padding: 24px 25px;
        color: $color-black;
        font-size: 17px;
        line-height: 25px;
        margin-top: 29px;
        p {
            margin-bottom: 0;
        }
        span {
            font-weight: 700;
        }
        img {
            margin-right: 0.5em;
        }
    }
    .order-confirmation-table {
        border: none;
        padding: 0;
        .total-value {
            background: $color-duck-green;
            color: #ffffff;
            font-family: $font-title;
        }
    }
    .media {
        background: $color-grey;
        padding: 16px;
        align-items: center;
        img.product-image {
            &.modal-cart__image {
                @media (max-width: 576px) {
                    max-width: 100px;
                }
            }
        }
        &-body {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            @include media-breakpoint-mobile {
                flex-direction: column;
            }
            .h5 {
                font-size: 17px;
                line-height: 25px;
                font-weight: 800;
                color: $color-duck-green;
                font-family: $font-title;
                @include media-breakpoint-mobile {
                    font-size: 15px;
                }
            }
            .ref-prod {
                font-family: $font-title;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                color: $color-black;
                @include media-breakpoint-mobile {
                    font-size: 13px;
                }
                span {
                    font-weight: 600;
                }
            }
            .qt-prod {
                font-family: $font-text;
                font-size: 15px;
                line-height: 14px;
                font-weight: 600;
                @include media-breakpoint-mobile {
                    font-size: 13px;
                }
            }
            .price-prod {
                font-family: $font-title;
                font-size: 30px;
                line-height: 30px;
                color: $color-green;
                font-weight: 700;
            }
        }
    }
}

